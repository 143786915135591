import React, { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { motion } from "framer-motion"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import "react-lazy-load-image-component/src/effects/opacity.css"

import ScrollAnimation from "react-animate-on-scroll"

import "./styles.scss"

const List = ({ content, handleOpenLightbox, toggleLightBox }) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useScrollPosition(({ currPos }) => {
    setScrollPosition(currPos.y)
  })

  const listImages = {
    out: {
      scale: 1.3,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
      },
    },
    in: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
        delay: 0.4,
      },
    },
  }

  return (
    <motion.ul
      variants={listImages}
      initial="in"
      animate={toggleLightBox ? "out" : "in"}
      className="paintingsGallery-list"
      style={{ transformOrigin: `center ${-scrollPosition}px ` }}
    >
      {content.paper_artworks.map((artwork, index) => (
        <li
          className="paintingsGallery-list-item"
          key={artwork.paper_image.url}
          onClick={() => {
            handleOpenLightbox(index)
          }}
        >
          <ScrollAnimation
            animateIn="slideIn"
            duration={1}
            delay={100}
            animateOnce
          >
            <div className="paintingsGallery-list-item-image">
              <LazyLoadImage
                src={artwork.paper_image.url}
                effect="opacity"
                style={{ height: "50vh", width: "100%", objectFit: "cover" }}
                alt={`paper ${content.gallery_year} ${artwork.painting_dimensions}`}
              />
              <p className="paintingsGallery-list-item-image-icon">+</p>
            </div>

            <div className="paintingsGallery-list-item-infos">
              <p>{content.gallery_number}</p>
              <p>{artwork.paper_dimensions}</p>
            </div>
          </ScrollAnimation>
        </li>
      ))}
    </motion.ul>
  )
}

export default List
