import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import { useSwipeable } from "react-swipeable"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"
import BackButton from "./BackButton"
import Year from "./Year"
import List from "./List"
import LightBox from "./LightBox"
import "./styles.scss"

const PaintingsGallery = ({ location, content }) => {
  const [current, setCurrent] = useState(0)
  const [slideTransition, setSlideTransition] = useState(false)
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const toggleLightBox = state.toggleLightBox
  const path = location.pathname
  const langSliced = location.pathname.slice(1, 3)
  const slides = content.paper_artworks
  const len = content.paper_artworks.length

  const handleOpenLightbox = index => {
    dispatch({ type: "TOGGLE_LIGHTBOX" })
    setCurrent(index)
  }

  const handleCloseLightBox = () => {
    dispatch({ type: "TOGGLE_LIGHTBOX" })
  }
  const handleNextSlide = () => {
    setSlideTransition(true)
    setTimeout(() => {
      setSlideTransition(false)
      setCurrent((current + 1) % len)
    }, 300)
  }

  const handlePrevSlide = () => {
    setSlideTransition(true)
    setTimeout(() => {
      setSlideTransition(false)
      setCurrent(current === 0 ? len - 1 : current - 1)
    }, 300)
  }
  const handleTransition = slug => {
    if (path !== `/${langSliced}${slug}`) {
      dispatch({ type: "TOGGLE_TRANSITION" })
      setTimeout(() => {
        navigate(`/${langSliced}${slug}`)
      }, 1000)
    } else {
      return
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleNextSlide()
    },
    onSwipedRight: () => {
      handlePrevSlide()
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })
  return (
    <section className="paintingsGallery">
      <div className="mobile-header" />
      <BackButton
        langSliced={langSliced}
        toggleLightBox={toggleLightBox}
        handleTransition={handleTransition}
      />
      <Year content={content} toggleLightBox={toggleLightBox} />
      <List
        content={content}
        handleOpenLightbox={handleOpenLightbox}
        toggleLightBox={toggleLightBox}
      />
      <LightBox
        content={content}
        slides={slides}
        current={current}
        langSliced={langSliced}
        toggleLightBox={toggleLightBox}
        slideTransition={slideTransition}
        handlers={handlers}
        handleCloseLightBox={handleCloseLightBox}
        handleNextSlide={handleNextSlide}
        handlePrevSlide={handlePrevSlide}
      />
    </section>
  )
}

export default PaintingsGallery
