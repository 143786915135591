import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import socialImage from "../../static/social-share/DMITRIEVA-ART-social-share.jpg"
import SEO from "../components/seo"
import PaperGallery from "../components/PaperGallery"

var arraySort = require("array-sort")

const PaperGalleryPage = ({ data, location }) => {
  const content = data.prismicPapergallery.data
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const number = content.gallery_number
  const toggleTransition = state.toggleTransition
  const path = location.pathname
  const langSliced = path.slice(1, 3)
  useEffect(() => {
    if (toggleTransition) {
      setTimeout(() => {
        dispatch({ type: "TOGGLE_TRANSITION" })
      }, 600)
    }
  }, [])
  return (
    <>
      <ParallaxProvider>
        <SEO
          title={
            langSliced === "fr"
              ? "Peintures " + number
              : langSliced === "ru"
              ? "Картины " + number
              : "Paintings " + number
          }
          image={socialImage}
          lang={langSliced}
          path={path}
        />
        <PaperGallery location={location} content={content} />
      </ParallaxProvider>
    </>
  )
}

export default PaperGalleryPage

export const query = graphql`
  query PaperGallery($lang: String!, $id: ID!) {
    prismicPapergallery(lang: { eq: $lang }, prismicId: { eq: $id }) {
      data {
        gallery_number
        paper_artworks {
          paper_dimensions
          paper_image {
            url(imgixParams: { w: 1000, q: 10 })
          }
        }
      }
    }
  }
`
